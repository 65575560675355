export const ShowTypes = new Map([
  ["T", "Text"],
  ["M", "Musical"],
  ["I", "Infantil"],
  ["X", "Taller de Teatre"],
]);

const generateSeasonsList = (startYear, endYear) => {
  const seasons = [];
  for (let year = startYear; year >= endYear; year--) {
    seasons.push(`${year}-${(year + 1).toString().slice(0)}`);
  }
  return seasons;
};

export const SeasonsList = generateSeasonsList(new Date().getFullYear(), 1983);

// TODO Automatizar esta lista para coger a partir del año actual
export const SeasonsList_OLD = [
  "2024-2025",
  "2023-2024",
  "2022-2023",
  "2021-2022",
  "2020-2021",
  "2019-2020",
  "2018-2019",
  "2017-2018",
  "2016-2017",
  "2015-2016",
  "2014-2015",
  "2013-2014",
  "2012-2013",
  "2011-2012",
  "2010-2011",
  "2009-2010",
  "2008-2009",
  "2007-2008",
  "2006-2007",
  "2005-2006",
  "2004-2005",
  "2003-2004",
  "2002-2003",
  "2001-2002",
  "2000-2001",
  "1999-2000",
  "1998-1999",
  "1997-1998",
  "1996-1997",
  "1995-1996",
  "1994-1995",
  "1993-1994",
  "1992-1993",
];
